var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "ruler" }, [
    _c(
      "div",
      {
        staticClass: "ruler-x",
        style: "left: " + _vm.rulerWidth + "px;height: " + _vm.rulerWidth + "px"
      },
      _vm._l(_vm.xlist, function(i, idx) {
        return _c(
          "div",
          {
            staticClass: "line-box",
            style:
              "left: " +
              idx * 100 * _vm.ratioX +
              "px;width:" +
              i * _vm.ratioX +
              "px"
          },
          [
            _vm._v(" " + _vm._s(idx * 100) + " "),
            _vm._l(9, function(n) {
              return _c("div", {
                staticClass: "line",
                style:
                  "left: " +
                  n * 10 * _vm.ratioX +
                  "px;height:" +
                  (n === 5 ? 8 : 5) +
                  "px"
              })
            })
          ],
          2
        )
      }),
      0
    ),
    _c(
      "div",
      {
        staticClass: "ruler-y",
        style:
          "left: " +
          _vm.rulerWidth +
          "px;top: " +
          _vm.rulerWidth +
          "px;height: " +
          _vm.rulerWidth +
          "px;width:" +
          _vm.height +
          "px"
      },
      _vm._l(_vm.ylist, function(i, idx) {
        return _c(
          "div",
          {
            staticClass: "line-box",
            style:
              "left: " +
              idx * 100 * _vm.ratioY +
              "px;width:" +
              i * _vm.ratioY +
              "px"
          },
          [
            _vm._v(" " + _vm._s(idx * 100) + " "),
            _vm._l(9, function(n) {
              return _c("div", {
                staticClass: "line",
                style:
                  "left: " +
                  n * 10 * _vm.ratioY +
                  "px;height:" +
                  (n === 5 ? 8 : 5) +
                  "px"
              })
            })
          ],
          2
        )
      }),
      0
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }