//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    rulerWidth: {
      "default": 0 },

    axisX: {
      "default": 900 },

    axisY: {
      "default": 500 } },


  data: function data() {
    return {
      width: 0,
      height: 0,
      ratioX: 1,
      ratioY: 1 };

  },
  computed: {
    xlist: function xlist() {
      return this.getlist(this.axisX);
    },
    ylist: function ylist() {
      return this.getlist(this.axisY);
    } },

  methods: {
    getlist: function getlist(num) {
      if (!num) {
        return [];
      }
      var ret = [];

      for (var i = 0; i * 100 < num; i++) {
        ret.push(100);
      }
      return ret;
    },
    init: function init() {
      this.width = this.$el.offsetWidth - this.rulerWidth;
      this.height = this.$el.offsetHeight - this.rulerWidth;
      this.ratioX = this.width / this.axisX;
      this.ratioY = this.height / this.axisY;
    } }

  // mounted() {
  //   this.init()
  // }
};